.root{
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 10px;
  position: relative;
  /* border-bottom: 2px solid black; */
}

.root::after {
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  content: "";
  border-bottom: 1px solid rgba(0, 0, 0, 0.54);
}

.root:hover::after {
  border-bottom: 2px solid black;
}

.label{
  margin-bottom: 5px;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.54);
}

.datetimeWrap{
  margin-bottom: 3px;
}

.custom{
  display: inline-block;
}
.liveEventSelectWrap{
  margin-left: 100px;
  width: 200px;
}

.liveEventSelect{
  width: 100%;
}

.cellDark{
  border-bottom: 1px solid black !important;
}

.paperDark{
  background-color: #E2E2E2 !important;
}